<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
         Reservas
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <ReservasDataTable :headers="headers" :boards="boards">

       </ReservasDataTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import ReservasDataTable from '@/components/ReservasDataTable.vue' 
export default {
  name: 'Reservas',
  components: {
    ReservasDataTable
  },
  data() {
    return {
      headers: {
        reservation:[
          {
            text: 'No. Interno',
            align: 'center',
            value: 'id',
          },
          {
            text: 'Fecha Tour',
            align: 'center',
            value: 'travel_date',
          },
          {
            text: 'Booking Ref',
            align: 'center',
            value: 'booking_reference',
          },
          {
            text: 'Pax',
            align: 'center',
            value: 'lead_traveler_name',
          },
          {
            text: 'Ttl Pax',
            align: 'center',
            value: 'pax',
          },
          // {
          //   text: 'Tour',
          //   align: 'center',
          //   value: 'tour_name',
          // },
          // {
          //   text: 'Hora',
          //   align: 'center',
          //   value: 'tour_grade_code',
          // },
          {
            text: 'Total Reserva',
            align: 'end',
            value: 'amount',
          },
          {
            text: 'Tour',
            align: 'center',
            value: 'product_name'
          },
          
          {
            text: 'Estado Reserva',
            align: 'center',
            value: 'reservation_statusId.name',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ]

      },
      boards: {
        reservation:'reservation'
      }
    }
  },
}
</script>

<style>

</style>