<template>
  <div>
    <v-card class="rounded-xl">
      <v-card-title class="py-2">
        <v-row align="center">
          <v-col cols="12" md="3" >
            <h5>Reservas</h5>
          </v-col>
          <v-col cols="12" md="4" >
            <v-text-field
              class="pt-0"
              v-model="reservationSearch"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
            ></v-text-field>    
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" md="2" class="d-flex justify-center">
            <v-btn
              dense
              small
              color="primary"
              @click="openCreateForm(boards.reservation)"
            >
              Crear Reserva
            </v-btn>
          </v-col>
          <v-col md="1">
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="$router.push({name:'Invoice'})"
            >
              <v-icon dark>
                mdi-arrow-right-bold-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-data-table          
          dense
          :headers="headers.reservation"
          :items="foliosReservas"
          :items-per-page="15"
          multi-sort
          item-key="name"
          class=" rounded-xl"
          :search="reservationSearch"
          :loading="loadingTable"
          loading-text="Loading... Please wait"
          sort-by="id"
          sort-desc
          :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-left-circle-outline',
              lastIcon: 'mdi-arrow-right-circle-outline',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              showCurrentPage: true
            }"
          >
          <template v-slot:item="{ item, headers, isMobile }">
            <tr v-if="!isMobile">
              <td class="text-custom text-center text-caption blue-grey--text text--darken-1" >{{ item.id }}</td>
              <td class="text-custom text-center text-caption blue-grey--text text--darken-1" >{{ item.travel_date }}</td>
              <td class="text-caption text-center blue-grey--text text--darken-1  d-flex">{{ item.booking_reference }}</td>
              <td class="text-center text-caption blue-grey--text text--darken-1">{{ item.lead_traveler_name  }}</td>
              <td class="text-center text-caption blue-grey--text text--darken-1">{{ item.pax  ? item.pax : ''}}</td>
              <!-- <td class="text-custom text-right text-caption blue-grey--text text--darken-1">{{ item.tour_name ? item.tour_name.slice(0,29) : ''}}</td> -->
              <!-- <td class="text-custom text-right text-caption blue-grey--text text--darken-1">{{ item.tour_grade_code ? item.tour_grade_code : ''}}</td> -->
              <td class="text-right text-caption blue-grey--text text--darken-1">{{ item.amount ? item.amount : ''}}</td>
              <td class="text-center text-caption blue-grey--text text--darken-1">{{  item.product_name }}</td>
              <td class="text-center text-caption blue-grey--text text--darken-1">{{ item.reservation_statusId ? item.reservation_statusId.name : ''}}</td>
              <!-- <td class="text-right text-caption blue-grey--text text--darken-1">{{ `${item.arrival_airline ? item.arrival_airline : ''}-${item.arrival_flight_no ? item.arrival_flight_no : ''}--${item.arrival_time ? item.arrival_time : ''}`}}</td> -->
              <!-- <td class="text-right text-caption blue-grey--text text--darken-1">{{ `${item.departure_date ? item.departure_date : ''}-${item.departure_airline ? item.departure_airline : ''}-${item.departure_flight_no ? item.departure_flight_no : ''}--${item.departure_time ? item.departure_time : ''}`}}</td> -->
              
              <td class="text-center pr-1 d-flex align-center justify-center">
                <!-- boton confirmacion -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      small 
                      color="blue accent-3" 
                      v-if="item.reservation_statusId.id == 1" 
                      @click="openConfirmation(item)"
                      v-bind="attrs"
                      v-on="on"
                      >
                        mdi-coffee-maker-check-outline
                    </v-icon>
                  </template>
                  <span>Confirmar</span>
                </v-tooltip>

                <!-- boton inspeccionar -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon >
                      <v-icon 
                        small 
                        dark 
                        color="primary" 
                        @click="openDialogInfoReservas(item)"
                        v-bind="attrs"
                        v-on="on"
                        >
                          mdi-table-eye
                        </v-icon>
                    </v-btn>
                  </template>
                  <span>Inspeccionar</span>
                </v-tooltip>

                <!-- boton editar -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      color="warning" 
                      small 
                      class="mr-2" 
                      @click="editarFila(item)"
                      v-bind="attrs"
                      v-on="on"
                      >
                        mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                
                <v-icon small color="warning" v-if="item.noCalendar.length > 0 && item.reservation_status < 4">
                  mdi-calendar-remove-outline
                </v-icon>
                <v-icon small color="primary-red" v-if="item.noInvoice.length != 0 && item.reservation_status < 4">
                  mdi-calendar-remove-outline
                </v-icon>
                <v-btn icon v-if="item.reservation_statusId.id < 4 && item.noInvoice.length == 0 && item.noCalendar.length > 0 && item.travel_date >= new Date().toISOString().substring(0, 10) && item.reservation_status < 4">
                  <v-icon small color="primary-red" @click="cancelReservations(item)">
                    mdi-close-circle-outline
                  </v-icon> 
                </v-btn>
                <v-btn icon v-if="item.noInvoice.length != 0 && item.reservation_status < 4">
                  <v-icon small color="primary-red" @click="cancelReservations(item)">
                    mdi-close-circle-outline
                  </v-icon> 
                </v-btn>
                
              </td>
              
            </tr>
            <tr v-if="isMobile">
              <v-card elevation="0">
                <v-container>
                  <td class="text-custom text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1" >
                    <p class="ma-0">
                      <strong>{{`${headers[0].text}: `}}</strong>
                    </p>
                    {{ `${item.travel_date ? item.travel_date: item.start_date ? item.start_date.split('T')[0] : ''}` }}
                  </td>
                  <td class="text-caption text-center blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                    <p class="ma-0">
                      <strong>{{`${headers[1].text}`}}</strong>
                    </p>
                    {{ item.booking_reference  ? item.booking_reference : item.start_date ? `BR-506T${item.id}` :''}}
                  </td>
                  <td class="text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                    <p class="ma-0">
                      <strong>{{`${headers[2].text}`}}</strong>
                    </p>
                    {{ item.lead_traveler_name  }}
                  </td>
                  <td class="text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                    <p class="ma-0">
                      <strong>{{`${headers[3].text}`}}</strong>
                    </p>
                    {{ item.travelers  ? item.travelers : ''}}
                  </td>
                  <td class="text-right text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                    <p class="ma-0">
                      <strong>{{`${headers[4].text}`}}</strong>
                    </p>
                    {{ item.net_rate ? item.net_rate : ''}}
                  </td>
                  <td class="text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                    <p class="ma-0">
                      <strong>{{`${headers[5].text}`}}</strong>
                    </p>
                    {{  product.find((art)=> art.code.split('-')[0] == item.product_code) ? product.find((art)=> art.code.split('-')[0] == item.product_code).short_name: '' }}
                  </td>
                  <td class="text-center text-caption blue-grey--text text--darken-1 d-flex justify-space-between mx-1">
                    <p class="ma-0">
                      <strong>{{`${headers[6].text}`}}</strong>
                    </p>
                    {{ item.reservation_statusId ? item.reservation_statusId.name : ''}}
                  </td>
                </v-container>
              </v-card>
              <td class="text-center pr-1 d-flex align-center justify-center">                         
                <v-btn dark icon >
                  <v-icon small dark color="primary" @click="openDialogInfoReservas(item)">mdi-table-eye</v-icon>
                </v-btn>
                <v-icon color="warning" small class="mr-2" @click="editarFila(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small color="warning" v-if="item.noCalendar.length > 0">
                  mdi-calendar-remove-outline
                </v-icon>
                <v-icon small color="primary-red" v-if="item.noInvoice.length != 0">
                  mdi-calendar-remove-outline
                </v-icon>
              </td>
              
              
            </tr>  
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Dialogo informacion adicional de reservas -->
    <div class="text-center">
      <v-dialog
        v-model="dialogInforReservas"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detalles Servicios de Reserva
          </v-card-title>

          <v-card-text>
            <v-data-table          
              dense
              :headers="headersInfoReserva"
              :items="folio"
              :items-per-page="15"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :loading="loadingTableInfoReservas"
              loading-text="Loading... Please wait"
              single-expand
              >
                
                <template v-slot:item="{ item, isExpanded, expand}">
                  <tr >
                    <td @click="expand(!isExpanded)" class="text-custom text-center text-caption blue-grey--text text--darken-1">{{ `FACT-${item.invoice_folioId.id}` }}</td>
                    <td @click="expand(!isExpanded)" class="text-custom text-center text-caption blue-grey--text text--darken-1">{{ `${item.invoice_folioId.date}` }}</td>
                    <td @click="expand(!isExpanded)" class="text-caption text-center blue-grey--text text--darken-1">{{ `${item.invoice_folioId.bill_state}`}}</td>
                    <td @click="expand(!isExpanded)" class="text-center text-caption blue-grey--text text--darken-1">{{ `${item.invoice_folioId.balance_due}` }}</td>
                    
                    <td class="text-center pr-1 d-flex align-center justify-center">                         
                      <v-icon color="green" class="mr-2" v-if="item.invoice_folioId.bill_state == 'PAGADA' && item.invoice_details.length == 2">
                        mdi-check-all 
                      </v-icon>
                      <v-icon color="warning" small class="mr-2" @click="editarFactura(item)">
                        mdi-pencil
                      </v-icon>
                      <div class="text-center" v-if="!showButton">
                        <v-progress-circular indeterminate color="blue"></v-progress-circular>
                      </div>
                      <v-btn dark icon v-if="showButton" >
                        <v-icon dark color="blue" @click="invoicePdf(item)">mdi-receipt-text</v-icon>
                      </v-btn>
                    </td>
                  </tr> 
                </template>
                <!-- parte expandible de la tabla con los detalles -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-card elevation="0" >
                      <v-list>
                        <template v-for="detalles in item.invoice_details" >
                         
                          <v-list-item :key="detalles.id">
                            <v-list-item-avatar >
                              <v-icon
                                color="white"
                                v-if="detalles.service_type == 'IN'"
                                small
                                :class="[
                                  detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV' ? 'orange': 
                                  detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' ? 'primary': 
                                  detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip' 
                                    || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla'
                                    || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'
                                    || detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' ? 'green' : 
                                  'blue lighten-1' ]"
                                dark
                              >
                                mdi-folder
                              </v-icon>
                              <v-icon
                                v-else
                                color="white"  
                                small
                                class="red lighten-1"
                                dark
                              >
                                mdi-folder
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title >
                                <span class="text-center text-overline blue-grey--text text--darken-1">
                                  {{item.reservationId.lead_traveler_name}}
                                  
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <span class="mr-2"><strong>Date:</strong>{{` ${detalles.travel_date ? detalles.travel_date: detalles.departure_date}`}}</span>
                                <span class="mr-2"><strong>No. Pax:</strong>{{` ${detalles.pax} Adultos ${detalles.pax_kids ? detalles.pax_kids + ' Niños': '' }` }}</span> <br>
                                <div class="mr-2" v-if="detalles.service_type == 'IN'">
                                  <div v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV'">
                                    <strong class="mr-3 orange--text" >ATV</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip'">
                                    <strong class="mr-3 green--text" >Zip Line</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla'">
                                    <strong class="mr-3 green--text" >Llanos cortes</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio'">
                                    <strong class="mr-3 green--text" >Rio Celeste</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'">
                                    <strong class="mr-3 green--text" >{{ detalles.product_invoice_detailId.short_name}}</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra'">
                                    <strong class="mr-3 primary--text" >Traslado</strong>
                                    <strong class="mr-1">Pick up:</strong>{{` ${ detalles.pick_up_location }`}}
                                    <strong class="mr-1">Drop Off:</strong>{{` ${  detalles.drop_off_location }`}}
                                  </div>
                                  <div v-else>
                                    <strong>Pick up Location:</strong>{{` ${ 'LIB ATO - ' + detalles.drop_off_location }`}}
                                  </div>
                                </div> 
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Drop Location:</strong>{{` ${ detalles.drop_off_location + ' - LIB ATO' }`}}</span> <br v-if="detalles.service_type == 'OUT'">
                                  
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Time:</strong>{{` ${ detalles.departure_time }`}}</span> 
                                <div class="mr-2 d-flex" v-if="detalles.service_type == 'IN'">
                                  <div 
                                    v-if="detalles.product_invoice_detailId.short_name.slice(0,3) == 'ATV' ||
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Zip' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Lla' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tra' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Rio' || 
                                    detalles.product_invoice_detailId.short_name.slice(0,3) == 'Tou'  
                                  
                                  ">
                                    <span class="mr-2" ><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    
                                  </div>
                                  <div v-else>
                                    <span class="mr-2" v-if="detalles.service_type == 'IN'"><strong>Time:</strong>{{` ${ detalles.arrival_time }`}}</span>
                                    <strong>Flight:</strong>{{` ${ detalles.arrival_airline + ' - ' + detalles.arrival_flight_no}`}}
                                  </div>
                                </div>
                                <span class="mr-2" v-if="detalles.service_type == 'OUT'"><strong>Flight</strong>{{` ${ detalles.departure_airline + ' - ' + detalles.departure_flight_no }`}}</span>
                                  
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-card-actions>
                              <v-tooltip top v-if="detalles.calendar_operation">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn 
                                  dark
                                  icon
                                  v-if="detalles.calendar_operationId"
                                  :href="detalles.calendar_operationId.htmlLink"
                                  target="_blank"
                                  >
                                    <v-icon                                       
                                      color="green" 
                                      small
                                      v-bind="attrs"
                                      v-on="on" 
                                      @click="editarFilaDetalleFactura(detalles)">
                                      mdi-calendar-multiple-check
                                    </v-icon>                                  
                                  </v-btn>
                                </template>
                                <span>Evento con calendario</span>
                              </v-tooltip>
                              <v-tooltip top v-else>
                                <template v-slot:activator="{ on, attrs }">
                                   <v-btn 
                                  dark
                                  icon
                                  v-if="detalles.calendar_operationId"
                                  :href="detalles.calendar_operationId.htmlLink"
                                  target="_blank"
                                  >
                                    <v-icon 
                                      color="red" 
                                      small
                                      v-bind="attrs"
                                      v-on="on" 
                                      @click="editarFilaDetalleFactura(detalles)">
                                      mdi-calendar-question
                                    </v-icon> 
                                  </v-btn>               
                                </template>
                                <span>Evento con calendario</span>
                              </v-tooltip>
                              
                              <v-icon color="warning" class="mr-2" @click="editarFilaDetalleFactura(detalles)">
                                mdi-arrow-right-bold-circle-outline
                              </v-icon>
                            </v-card-actions>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </td>
                </template>

          

        </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="closeDialogInfoReservas"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>


    <!-- Form -->
    <v-row justify="center" >
      <v-form ref="createReservasForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogReservas" persistent max-width="900px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Reserva ${createReservasForm.booking_reference}` : `Creacion de reservation` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md=6>                   
                     <v-autocomplete
                        class="text-caption"
                        v-model="createReservasForm.customer_reservation"
                        item-value="id"
                        dense
                        :items="customers"
                        :item-text="itemTextCustomer"
                        label="Nombre Cliente Reserva"
                        required
                        :rules="createFormRules"
                        clearable
                        :value="createReservasForm.customer_reservation"
                        @change="verificarCustomer"
                      ></v-autocomplete>
                  </v-col>
                   <v-col cols="12" md="4">
                      <v-fab-transition>
                         <v-btn v-if="!this.createReservasForm.customer_reservation"
                            fab
                            dark
                            small
                            color="indigo"
                            @click="openCustomerSimpleForm"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                      </v-fab-transition>
                    </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="12" md="3">
                    <v-menu
                      v-model="menuStarDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      required
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="createReservasForm.start_date"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="createFormRules"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="createReservasForm.start_date"
                        @input="menuStarDate = false"
                        :rules="createFormRules"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                   <v-menu
                      v-model="menuFinalDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :rules="createFormRules"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="createReservasForm.final_date"
                          label="Fecha Termina"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="createFormRules"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="createReservasForm.final_date"
                        @input="menuFinalDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createReservasForm.segment"
                      item-value="id"
                      dense
                      :items="segments"
                      :item-text="itemTextBoth"
                      :value="createReservasForm.segment"
                      label="Segmento"
                      required
                      :rules="createFormRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createReservasForm.market"
                      item-value="id"
                      dense
                      :items="markets"
                      :item-text="itemTextBoth"
                      label="Market"
                      required
                      :rules="createFormRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      :key="keyEmail"
                      dense
                      label="Correo electrónico"
                      v-model="createReservasForm.email"
                      disabled
                      required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md=6>
                    <v-autocomplete
                      class="text-caption"
                      v-model="createReservasForm.payer"
                      item-value="id"
                      dense
                      :items="customers"
                      :item-text="itemTextCustomer"
                      label="Pagador Reserva"
                      :rules="createFormRules"
                      required
                      :value="createReservasForm.payer"
                    ></v-autocomplete>
                  </v-col>
                
                </v-row>
                
                <v-row dense align="baseline">
                  <v-col cols=12 md=6>
                    <v-text-field
                      dense
                      label="Telefono"
                      v-model="createReservasForm.phone">
                    </v-text-field>
                  </v-col>
                  <v-col cols=12 md=6>
                    <v-textarea
                      label="Consideraciones especiales en esta reserva"
                      auto-grow
                      rows="4"
                      row-height="30"
                      v-model="createReservasForm.special_requirements"
                    ></v-textarea>
                  </v-col>
                </v-row>
               
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn 
                :disable="isLoading"
                v-if="!createReservasForm.email"
                color="blue darken-3" 
                text @click="actualizarCorreoTelefono(createReservasForm.customer_reservation)">
                Actualizar correo
              </v-btn>
              <v-btn 
                :disable="isLoading"
                v-if="createReservasForm.email"
                color="blue darken-3" 
                text @click="actualizarCorreoTelefono(createReservasForm.customer_reservation)">
                Editar correo 
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.reservation)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.reservation)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT' ,boards.reservation)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Creacion simple de customer desde este modulo -->
    <v-row justify="center" >
      <v-form ref="createCustomerForm" v-model="validCustomer" lazy-validation >
        <v-dialog  v-model="dialogCustomer" persistent max-width="500px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ `Creacion de cliente` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md=3>
                    <v-text-field
                      dense
                      label="Nombres"
                      v-model="createCustomerForm.first_name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md=3>
                    <v-text-field
                      dense
                      label="Apellidos"
                      v-model="createCustomerForm.last_name"
                      :rules="createFormRules"
                      @keyup.enter="summitForm('POST', 'customer')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md=3>
                    <v-text-field
                      dense
                      label="Email"
                      v-model="createCustomerForm.email"
                      @keyup.enter="summitForm('POST', 'customer')"
                    >
                    </v-text-field>
                  </v-col>
                  
                </v-row>
                     
               
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm('customer')">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnCustomerEditVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', 'customer')"
              >
                Guardar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnCustomerEditVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', 'customer', 'updateEmail')"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- DIALOG DELETE -->
    <!-- <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta compañía?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import {axiosN506, axiosB} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      keyEmail: 0,
      isLoading: false,
      loadingTable: false,
      loadingTableInfoReservas: false,
      dialogDelete: false,
      reservationSearch: '',
      reservationRecords: [],
      reservationsInfoRecords: [],
      // validacion email :rules="[v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido.']"
      //form
      menuStarDate: false,
      menuFinalDate: false,
      btnEdithVisible: false,
      btnCustomerEditVisible: false,
      valid: false,
      validCustomer: false,
      dialogReservas: false,
      dialogCustomer: false,
      dialogInforReservas: false,
      beforeCreateForm: {},
      createReservasForm: {},
      createCustomerForm: {},
      createFormRules: [
        v => !!v || 'El Campo es requerido',
      ],
      emailRules:[
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],

      segments: [],
      markets:[],
      customers: [],
      product: [],
      folio: [],
      folioAll: [],
      headersInfoReserva: [
        {
          text: 'No. Factura',
          align: 'center',
          value: 'invoice_folioId.id',
        },
        {
          text: 'Fecha',
          align: 'center',
          value: 'invoice_folioId.date',
        },
        {
          text: 'Estado Factura',
          align: 'center',
          value: 'invoice_folioId.bill_state',
        },
        {
          text: 'Balance',
          align: 'rigth',
          value: 'invoice_folioId.balance_due',
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions',
        },
        // { text: "", sortable: false, width: 100 },
      ],
      expanded: [],
      showButton: true
    }
  },
  computed: {
    // nombreCompleto(){
    //   console.log('leer nombre de customer')
    //   let first_name = this.createReservasForm.first_name ? this.createReservasForm.first_name : ''
    //   let last_name = this.createReservasForm.last_name ? this.createReservasForm.last_name : ''
    //   const completo = first_name + ' ' + last_name

    //   this.createReservasForm.customer_reservation = this.customers.find((nombre)=> 
    //     nombre.first_name.toLowerCase() == first_name.toLowerCase()
    //   )
    //   this.createReservasForm.payer = this.createReservasForm.customer_reservation
    //   console.log(this.createReservasForm.customer_reservation)
    //   return completo
    // },
    foliosReservas(){

      for (let resv of this.reservationRecords){
        // console.log('pase por aqui')
        resv.noCalendar = []
        resv.noInvoice = []
        resv.travel_date = `${resv.travel_date ? resv.travel_date: resv.start_date ? resv.start_date.split('T')[0] : ''}`
        resv.booking_reference = resv.booking_reference  ? resv.booking_reference : resv.start_date ? `BR-506T${resv.id}` :''
        resv.product_name = this.product.find((art)=> art.code.split('-')[0] == resv.product_code) ? this.product.find((art)=> art.code.split('-')[0] == resv.product_code).short_name: ''
        for (let folio of this.folioAll){
          // if (!folio.reservationId) console.log(folio)
          if (folio.reservationId && resv.id == folio.reservationId.id){
            if (folio.invoice_details.length === 0){
              resv.noInvoice.push(folio.id)
            }
          }
          if (folio.reservationId && resv.id == folio.reservationId.id && folio.invoice_details){
            for (let invDetail of folio.invoice_details){
              if (!invDetail.calendar_operation){
                resv.noCalendar.push(invDetail)
              }
            }
          }
        }
      }
      return this.reservationRecords
    }

  },
  methods: {
    //Metodo de confirmacion de resevas, 2 modos, con envio de correo y sin envio de correo

    //Metodo para actualizar reservas que no posean correo y quieran ser confirmadas
    actualizarCorreoTelefono(item){
      this.btnCustomerEditVisible = true
      this.createCustomerForm = {}
      
      let customer = this.customers.find((nombre)=>nombre.id == item)
      this.createCustomerForm.first_name = customer.first_name
      this.createCustomerForm.last_name = customer.last_name
      this.createCustomerForm.email - customer.email

      this.dialogCustomer = true
      
      
    },

    //Metodo de cancelacion de reservas
    async cancelReservations(item){
      console.log(item)
      this.isLoading = true
      let answer = confirm("Para cancelar esta reserva se debieron eliminar primero los eventos de calendario que esta pueda contendes. \
                            Se borraran todas las facturas, recibos y servicios asociados a la misma, Deseas realmente cancelar esta reserva?")
      if (answer){
        
        const url = `/reservation/cancel`
        let body = {id : item.id}
        await this.$store.dispatch('verifyToken')
        axiosN506({
          method: 'POST',
          url: url,
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          data: body
        })
        .then((result) => {
          console.log(result)
          this.getData('folioAll')
          this.getData(this.boards.reservation)
          this.isLoading = false
        }).catch((err) => {
          console.log(err)
          this.isLoading = false
        });
        
      }else {
        this.isLoading = false
      } 
    },
    openDialogInfoReservas (item){
      let obj = [item.id]
      this.getData('folio', obj)
      this.dialogInforReservas = true
    },
    closeDialogInfoReservas (){
      this.dialogInforReservas = false
      this.reservationsInfoRecords = []
      this.folio = []
    },
    verificaCustomer(){
      console.log(this.createReservasForm.customer_reservation)
    },
    invoicePdf(item){
      const url = `https://reports506.webdgroup.com/api/report`
      this.showButton = false
      let body = null
      
      if (item.invoice_details.length) {
        item.invoice_details.sort((a, b) => {
            let positionA = 0
            let positionB = 0

            if (a.travel_date) positionA = new Date(a.travel_date).getTime()
            if (a.departure_date) positionA = new Date(a.departure_date).getTime()
            if (b.travel_date) positionB = new Date(b.travel_date).getTime()
            if (b.departure_date) positionB = new Date(b.departure_date).getTime()
    
            if (positionA > positionB) {
                return 1
            } else if (positionA < positionB) {
                return -1
            }
        })
        
        //Registro hecho desde la unterfaz de viator
        if (item.reservationId.booking_reference.slice(0,7) != 'BR-506T' && item.reservationId.booking_reference.slice(0,6) != 'BR-WEB' ) {
          body = {
              template: { _id : "nEbhVdc9LSNsm9Rb"  },
              data : {
                  booking_confirmation: `FACT-${item.invoice_folio} - ${item.reservationId.booking_reference}`,
                  date: `${item.reservationId.travel_date}`,
                  due: item.invoice_folioId.due,
                  balance_due: item.invoice_folioId.balance_due,
                  client: `${item.reservationId.lead_traveler_name}`,
                  subtotal: `${item.reservationId.net_rate.split('$')[1]}`,
                  total: `${item.reservationId.net_rate.split('$')[1]}`,
                  paid: `${item.reservationId.payment_amount || '0.00'}`,
                  paid_date: `${item.reservationId.travel_date}`,
                  items: [],
                  containt_receipts: item.receipts ? true : false,
                  receipts: item.receipts
              },
              options: { reports: { save: false } }
          }
    
          let detail_amount = parseFloat(item.reservationId.net_rate.split('$')[1]) / item.invoice_details.length
    
          for (let [i, detail] of item.invoice_details.entries()) {
            let obj = {
                name: detail.traveler_names,
                description: {
                    date: `${detail.service_type == 'IN' ? detail.travel_date : detail.departure_date}`,
                    people_amount: `${detail.pax + ' Adults'} ${detail.pax_kids ? detail.pax_kids + ' Kids' : ''}`, 
                    // arrival_time: `${detail.service_type == 'IN' ?  detail.arrival_time : detail.departure_time}`,
                    pick_up: detail.pick_up_location,
                    drop_off: detail.drop_off_location,
                    special_requirements: detail.special_requirements
                },
                rate: `${detail.rate}`,
                qty: detail.quantity,
                amount: `${detail_amount.toFixed(2)}`,
                skip: false
            }

            if (i > 2 && i % 3 === 0) obj.skip = true
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Zip') obj.product_name = `ATV + ZIPLINE ${detail.service_type}`

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tra') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()}`
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One') {
              if (detail.product_invoice_detailId.product_name) {
                obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
              } else {
                obj.product_name = `${detail.product_invoice_detailId.name.toUpperCase()} - ${detail.service_type}`
              }
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Lla') {
              obj.product_name = `LLANOS CORTES ${detail.service_type}`
              obj.description.tour_green = true
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Rio') {
              obj.product_name = `RÍO CELESTE ${detail.service_type}`
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'ATV') {
              obj.product_name = detail.product_invoice_detailId.product_name.toUpperCase()
              obj.description.tour_atv = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tou') {
              obj.product_name = detail.product_invoice_detailId.name
              body.data.policy_extra = true
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Sco') {
              obj.product_name = 'DAY HONDA NAVI MOTORCICLE RENTAL'
              obj.policy_extra_moto = true
              body.data.policy_extra_moto = true
              obj.description.departure_date = detail.departure_date
              obj.description.moto = detail.arrival_flight_no
            }
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Otr') {
              obj.product_name = detail.product_invoice_detailId.short_name
              obj.description.moto = detail.arrival_flight_no
              obj.extra_otro = true
            }
            
            if (detail.service_type == 'IN' && detail.arrival_airline && detail.arrival_flight_no) {
              obj.description.flight = `${detail.arrival_airline} - ${detail.arrival_flight_no}`
            } else if (detail.service_type == 'OUT' && detail.departure_airline && detail.departure_flight_no) {
              obj.description.flight = `${detail.departure_airline} - ${detail.departure_flight_no}`

              if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One' || detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
                // let dateGoogle = this.convertDateCalendarGoogle(detail.departure_date, detail.departure_time, 3, '-')
                obj.description.pick_up_time = detail.pick_up_departure_time
              }
            }

            if (detail.service_type == 'IN') {
              obj.description.arrival_time = detail.arrival_time
            } else {
              obj.description.departure_time = detail.departure_time
            }

            body.data.items.push(obj)
          }
        } else {
          //Registro hecho manualmente
          body = {
            template: { _id : "nEbhVdc9LSNsm9Rb"  },
            data : {
              booking_confirmation: item.reservationId.booking_reference.slice(0,6) == 'BR-WEB' ? `FACT-${item.invoice_folio} - ${item.reservationId.booking_reference}` : `FACT-${item.invoice_folio} - BR-506T${item.reservationId.id}`,
              date: `${item.reservationId.final_date.substring(0, 10)}`,
              due: item.invoice_folioId.due,
              balance_due: 0,
              client: `${item.reservationId.payerId.first_name} ${item.reservationId.payerId.last_name}`.toUpperCase(),
              subtotal: 0,
              total: 0,
              paid: `${item.reservationId.payment_amount || '0.00'}`,
              paid_date: `${item.reservationId.travel_date || ''}`,
              items: [],
              containt_receipts: item.receipts ? true : false,
              receipts: item.receipts
            },
            options: { reports: { save: false } }
          }
    
          for (let [i, detail] of item.invoice_details.entries()) {
            let obj = {
              name: detail.traveler_names,
              description: {
                date: `${detail.service_type == 'IN' ? detail.travel_date : detail.departure_date}`,
                people_amount: `${detail.pax + ' Adults'} ${detail.pax_kids ? detail.pax_kids + ' Kids' : ''}`,
                pick_up: detail.pick_up_location,
                drop_off: detail.drop_off_location,
                special_requirements: detail.special_requirements
              },
              rate: `${detail.rate}`,
              qty: detail.quantity,
              amount: `${this.convertToMoney(detail.amount)}`,
              skip: false
            }
            
            if (i > 2 && i % 3 === 0) obj.skip = true

            body.data.balance_due += detail.amount
            body.data.total += detail.amount
            body.data.subtotal += detail.amount
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Zip') obj.product_name = `ATV + ZIPLINE ${detail.service_type}`
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tra') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()}`
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
            }
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One') {
              obj.product_name = `${detail.product_invoice_detailId.product_name.toUpperCase()} - ${detail.service_type}`
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Lla') {
              obj.product_name = `LLANOS CORTES ${detail.service_type}`
              obj.description.tour_green = true
            }
            
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Rio') {
              obj.product_name = `RÍO CELESTE ${detail.service_type}`
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'ATV') {
              obj.product_name = detail.product_invoice_detailId.product_name.toUpperCase()
              obj.description.tour_atv = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Tou') {
              obj.product_name = detail.product_invoice_detailId.name
              body.data.policy_extra = true
              obj.description.tour_green = true
            }

            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Sco') {
              obj.product_name = 'DAY HONDA NAVI MOTORCICLE RENTAL'
              obj.policy_extra_moto = true
              body.data.policy_extra_moto = true
              obj.description.departure_date = detail.departure_date
              obj.description.moto = detail.arrival_flight_no
            }
            if (detail.product_invoice_detailId.short_name.slice(0,3) == 'Otr') {
              obj.product_name = detail.product_invoice_detailId.short_name
              obj.description.moto = detail.arrival_flight_no
              obj.extra_otro = true
            }
            
            if (detail.service_type == 'IN' && detail.arrival_airline && detail.arrival_flight_no) {
              obj.description.flight = `${detail.arrival_airline} - ${detail.arrival_flight_no}`
            } else if (detail.service_type == 'OUT' && detail.departure_airline && detail.departure_flight_no) {
              obj.description.flight = `${detail.departure_airline} - ${detail.departure_flight_no}`

              if (detail.product_invoice_detailId.short_name.slice(0,3) == 'One' || detail.product_invoice_detailId.short_name.slice(0,3) == 'R-T') {
                // let dateGoogle = this.convertDateCalendarGoogle(detail.departure_date, detail.departure_time, 3, '-')
                obj.description.pick_up_time = detail.pick_up_departure_time
              }
            }

            if (detail.service_type == 'IN') {
              obj.description.arrival_time = detail.arrival_time
            } else {
              obj.description.departure_time = detail.departure_time
            }

            body.data.items.push(obj)
          }

          body.data.balance_due = this.convertToMoney(body.data.balance_due)
          body.data.total = this.convertToMoney(body.data.total)
          body.data.subtotal = this.convertToMoney(body.data.subtotal)
        }
      }

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic YWx1Y2FyZG86QW5kMTE3MDAyMzIzNjE3QCE=`
        }
      })
      .then(res => {
        res.blob().then(file => {
          this.showButton = true
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
      })
      .catch(err => {
        this.showButton = false
        console.error(err)
      })
    },
    async summitForm(method, board, updateEmail){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.reservation && method == 'POST') {
        if (!this.$refs.createReservasForm.validate()) {
          error = true
        } else {
          this.createReservasForm.reservation_status = 1
          form = this.createReservasForm
        }
      }

      if (board == 'customer') {
        if (!this.$refs.createCustomerForm.validate()) {
          error = true
        } else {
          //Capitalize
          this.createCustomerForm.first_name = this.createCustomerForm.first_name[0].toUpperCase() + this.createCustomerForm.first_name.substring(1).toLowerCase()
          this.createCustomerForm.last_name = this.createCustomerForm.last_name[0].toUpperCase() + this.createCustomerForm.last_name.substring(1).toLowerCase()
          
          form = this.createCustomerForm
        }
      }

      //  if (board == this.boards.reservation_status) {
      //   if (!this.$refs.createReservationStatusForm.validate()) {
      //     error = true
      //   } else {
      //     form = this.createReservationStatusForm
      //   }
      // }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((result) => {
            if (board == 'customer') {
              this.createReservasForm.customer_reservation = result.data.data.id
              this.createReservasForm.payer = result.data.data.id
              // this.keyEmail +=1
              this.createReservasForm.email = result.data.email ? result.data.email.email : null
              this.isLoading = false
            }
            // if (this.createReservasForm.email ){
            //   this.updateCustomerEmail(result.data.data.id, this.createCustomerForm.email, 'POST')

            // }
            
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            console.log(err)
            alert(`error: ${err.response ? err.response.message : err}`)
            this.isLoading = false
          })
        } else if (method == 'PUT') {
          let body = { id: this.selectedRecord, ...this.createReservasForm }
          if (updateEmail) {
            board = 'email'
            body = { customer: this.createReservasForm.customer_reservation, email: this.createCustomerForm.email}
          }
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: body,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            if (board == 'email'){
              this.createReservasForm.email = this.createCustomerForm.email
              board = 'customer'
              this.getData(board)
              this.closeForm(board)

            }
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
      
      // this.isLoading = true
    },
    async updateCustomerEmail(customer, email, method){
      
      let url = ''
      let form = {}
      if (method == 'POST'){
        url = `email/add`
        form = { customer: customer, email: email }
      } 

      if (method == 'PUT'){
        url = `email/update`
        form = { customer: customer, email: email }
      }

      await this.$store.dispatch('verifyToken')
      axiosN506(url, {
        method: method,
        data: form,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((res) => {
        // console.info(res.data)
        this.createReservasForm.email = email
        this.isLoading = false
        this.dialogCustomer = false
      }).catch((err) => {
        const mensaje = err.response.data.message.map(item => item.type == "unique violation" ? 'correo ya fue asignado': '').join(' \n')
        alert(`error: ${err.response ? mensaje : err}`)
        this.isLoading = false
      })
    },
    openCreateForm(board) {
      if (board == this.boards.reservation) this.dialogReservas = true
    },
    openCustomerSimpleForm() {
      this.dialogCustomer = true
    },
    closeCustomerSimpleForm() {
      this.dialogCustomer = false
      this.createCustomerForm = {}
    },
    closeForm(board) {
      if (board == this.boards.reservation) {
        this.dialogReservas = false
        this.$refs.createReservasForm.resetValidation()
        this.createReservasForm = {}
      }
      if (board == 'customer') {
        this.closeCustomerSimpleForm()
        this.$refs.createCustomerForm.resetValidation()
      }
    },
    //metodos para update calendario y update fila de factura
    editarFilaDetalleFactura(item){
      this.$router.push({
        name: "Invoice",
        params: { item }
      })
    },
    editarFactura(item){
      this.$store.dispatch("verifyTokenPy")

      axiosB(`/extractor/api/506transfers/BuscarEvento`,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.$store.state.accessTokenPy}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log(response)
      })
      .catch((err)=>{
        console.log(err)
      })
    },
    editarFila(item) {
      const emailCustomer = this.customers.find((nombre)=> nombre.id == item.customer_reservation)
      
      this.selectedRecord = item.id
      this.createReservasForm.customer_reservation = item.customer_reservation
      this.createReservasForm.start_date = item.email_operationId ? item.travel_date.substring(0, 10):  item.start_date.substring(0, 10)
      this.createReservasForm.final_date = item.departure_date ? item.departure_date.substring(0, 10) : item.email_operationId ? item.travel_date.substring(0, 10) :item.final_date.substring(0, 10)
      this.createReservasForm.segment = item.segment
      this.createReservasForm.market = item.market
      this.createReservasForm.email = emailCustomer && emailCustomer.emailId ? emailCustomer.emailId.email: null
      this.createReservasForm.payer = item.payer
      this.createReservasForm.phone = item.phone ? item.phone : null
      this.createReservasForm.special_requirements = item.special_requirements
      this.createReservasForm.booking_reference = item.booking_reference

      this.dialogReservas = true
      this.btnEdithVisible = true
    },
    //metodos de manejo de la info
    // async getbyid(item){
    //   await this.$store.dispatch('verifyToken')
    //   axiosN506({
    //     method: "GET",
    //     url: `/email/id/:${item}`,
    //     headers: { Authorization: `Bearer ${this.$store.state.accessToken}` }
    //   })
    //   .then((result) => {
    //     console.log(result)
    //   }).catch((err) => {
        
    //   });
    // },
    async getData(board, obj){
      let url = `${board}/list`
      let method = 'GET'
      let body = {}

      if (board == 'folio') {
        url = `${board}/listWithDetails` 
        method = 'POST'
        body.records = obj
      }
      if (board == 'folioAll') {
        obj = []
        url = `folio/listWithDetails` 
        method = 'POST'
        body.records = obj
      }
        
      
      if (board == this.boards.reservation) {
        this.loadingTable = true
        url = `${board}/list`
      }
      await this.$store.dispatch('verifyToken')
      axiosN506({
        method: method,
        url: url,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        data: body
      })
      .then((result) => {       
        if (board == this.boards.reservation) {
          this.loadingTable = false
          this.reservationRecords = result.data.data
        } else if (board == 'segment'){
          this.segments = result.data.data
        } else if (board == 'market') {
          this.markets = result.data.data
        } else if (board == 'customer'){
          this.customers = result.data.data
        } else if (board == 'product'){
          this.product = result.data.data
        } else if (board == 'folio') {
          this.folio = result.data.data.filter((item)=> !!item.invoice_folio)
        } else if (board == 'folioAll'){
          // console.log(result.data.data)
          this.folioAll = result.data.data
          this.foliosReservas
        }           
      }).catch((err) => {
        console.error(err)
        this.loadingTable = false
      });
    },
    itemTextBoth(item){
      return `${item.id} - ${item.name}`
    },
    itemTextCustomer(item){
      return `${item.first_name} ${item.last_name}`
    },
    verificarCustomer(item){
      let selectedCustomer = this.customers.find((nombre)=> 
        nombre.id == item
      )
      this.createReservasForm.email = selectedCustomer.emailId ? selectedCustomer.emailId.email : null
      this.createReservasForm.payer = item
      this.createReservasForm.phone = selectedCustomer.celphone ? selectedCustomer.celphone : null 
    },
    
    convertToMoney(x) {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.reservation)
    this.getData('segment')
    this.getData('market')
    this.getData('customer')
    this.getData('product')
    this.getData('folioAll')
    
  }
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 24px !important;
  padding: 0 10px !important;
}
.tr-custom{
  box-sizing: border-box;
}

</style>